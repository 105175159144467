import React, {StrictMode} from 'react';
import './App.css';
import Home from "./components/section/Home";
import Resume from "./components/section/Resume";
import Contact from "./components/section/Contact";
import {Data, getDataModel} from "./service/Data";
import Header from "./components/section/Header";

function App() {
    const [isLoadingData, setisLoadingData] = React.useState(false);
    const [data, setData] = React.useState({} as Data);
    const [showData, setShowData] = React.useState(false);

    function loadData() {
        setisLoadingData(true);
        setShowData(true)
        const url = "https://giovanniliboni-67628-default-rtdb.europe-west1.firebasedatabase.app/.json";
        fetch(url)
            .then((response) => response.json())
            .then((json) => {
                setisLoadingData(false);
                setData(getDataModel(json))
            })
            .catch((error) => console.log(error));
    }

    React.useEffect(() => {
        loadData()
    }, []);

    return (
        <StrictMode>
            <>
                {showData && !isLoadingData ? <>
                    {Header(data as Data)}
                    <div className="content-area">
                        <div className="animated-sections">
                            {Home(data as Data)}
                            {Resume(data as Data)}
                            {Contact(data as Data)}
                        </div>
                    </div>
                </> : <>
                    <p>isLoadingData</p>
                </>}
            </>
        </StrictMode>
    );
}

export default App;
