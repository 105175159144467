import React from 'react';
import {Data} from "../../service/Data";

export default function AboutMe(data: Data) {
  return (
      <>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <div className="block-title">
              <h3>About Me</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-7">
            <p>{data.personal_info}</p>
          </div>
          <div className="col-xs-12 col-sm-5">
            <div className="info-list">
              <ul>
                <li>
                  <span className="title">Address</span>
                  <span className="value">{data.address}</span>
                </li>
                <li>
                  <span className="title">E-mail</span>
                  <span className="value">{data.email}</span>
                </li>
                <li>
                  <span className="title">Phone</span>
                  <span className="value">{data.mobile_phone}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
  );
}
