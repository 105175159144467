import React from 'react';
import {Data, Skill} from "../../service/Data";
import AboutMe from "./AboutMe";
import ResumeSection from "./ResumeSection";

export default function Resume(data: Data) {
  return (
      <>
        <section data-id="resume" className="animated-section">
          <div className="section-content">
            <div className="page-title">
              <h2>Resume</h2>
            </div>

            {AboutMe(data)}

            <div className="white-space-50"></div>

            <div className="row">
              <div className="col-xs-12 col-sm-7">
                {ResumeSection("Experience", data.experience)}
                <div className="white-space-50"></div>
                {ResumeSection("Education", data.education)}
              </div>

              <div className="col-xs-12 col-sm-5">
                <div className="block-title">
                  <h3>Skills</h3>
                </div>

                <div className="skills-info skills-second-style">
                  {data.skills.map((skill: Skill, i) =>
                    <>
                      <div className="skill clearfix">
                        <h4>{skill.value}</h4>
                        <div className="skill-value">{skill.score / 10 * 100}%</div>
                      </div>
                      <div className={`skill-container`}>
                        <div className="skill-percentage" style={{'width': `${ skill.score / 10 * 100 }%`}}></div>
                      </div>
                    </>
                  )}
                </div>

                <div className="white-space-10"></div>

                <div className="block-title">
                  <h3>
                    Knowledges
                  </h3>
                </div>

                <ul className="knowledges">
                  {data.knowledges.map((knowledge: string, i) =>
                    <>
                      <li key={i}>{knowledge}</li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="white-space-50"></div>
          </div>
        </section>
      </>
  );
}
