export interface Data {
  name: string
  titles: string[]
  address: string
  email: string
  mobile_phone: string
  social: Social
  personal_info: string,
  experience: ResumeSectionDataItem[],
  education: ResumeSectionDataItem[],
  skills: Skill[],
  knowledges: string[]
}

export interface Social {
  linkedin: string | null
  facebook: string | null
  twitter: string | null
}

export interface ResumeSectionDataItem extends Sortable {
  company: string
  text: string
  timeInterval: string
  title: string
}

export interface Sortable {
  sort: number
  value: string
}

export interface Item extends Sortable {
  sort: number
  value: string
}

export interface Skill extends Item {
  score: number
}

export function getDataModel(data: any): Data {
  const {name, titles, address, email, mobile_phone, social, personal_info, experience, education, skills, knowledges}: {
    name: any; titles: Item[]; address: any;
    email: any; mobile_phone: any; social: any;
    personal_info: any; experience: ResumeSectionDataItem[]; education: ResumeSectionDataItem[]
    skills: Skill[]; knowledges: Item[];
  } = data
  return {
    titles: Object.values(titles).sort((a, b) => (a.sort > b.sort) ? 1 : -1).map((obj) => obj.value),
    knowledges: Object.values(knowledges).sort((a, b) => (a.sort > b.sort) ? 1 : -1).map((obj) => obj.value),
    skills: Object.values(skills).sort((a, b) => (a.sort > b.sort) ? 1 : -1),
    experience: Object.values(experience).sort((a, b) => (a.sort > b.sort) ? 1 : -1),
    education: Object.values(education).sort((a, b) => (a.sort > b.sort) ? 1 : -1),
    ...{name, address, email, mobile_phone, social, personal_info}
  } as Data
}
