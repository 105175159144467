import React from 'react';
import {Data} from "../../service/Data";

export default function Contact(data: Data) {
  return (
    <>
      <section data-id="contact" className="animated-section">
        <div className="section-content">
          <div className="page-title">
            <h2>Contact</h2>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="lm-info-block gray-default">
                <i className="lnr lnr-envelope"></i>
                <h4>{data.email}</h4>
                <span className="lm-info-block-value"></span>
                <span className="lm-info-block-text"></span>
              </div>
            </div>
            <div className="col-sm">
              <div className="lm-info-block gray-default">
                <i className="lnr lnr-phone-handset"></i>
                <h4>{data.mobile_phone}</h4>
                <span className="lm-info-block-value"></span>
                <span className="lm-info-block-text"></span>
              </div>
            </div>
            <div className="col-sm">
              <div className="lm-info-block gray-default">
                <i className="lnr lnr-map-marker"></i>
                <h4>{data.address}</h4>
                <span className="lm-info-block-value"></span>
                <span className="lm-info-block-text"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
