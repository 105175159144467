import React from 'react';
import {ResumeSectionDataItem} from "../../service/Data";

export default function ResumeSectionItem(dataItem: ResumeSectionDataItem) {
  return (
      <>
        <div className="timeline-item clearfix" key={dataItem.sort}>
          <div className="left-part">
            <h5 className="item-period">{dataItem.timeInterval}</h5>
            <span className="item-company">{dataItem.company}</span>
          </div>
          <div className="divider"></div>
          <div className="right-part">
            <h4 className="item-title">{dataItem.title}</h4>
            <p>{dataItem.text}</p>
          </div>
        </div>
      </>
  );
}
