import React from 'react';
import {Data} from "../../service/Data";

export default function Header(data: Data) {
  return (
    <>
      <header id="site_header" className="header mobile-menu-hide">
        <div className="header-content">
          <div className="header-photo">
            <img src="img/main_photo.jpg" alt={data.name} />
          </div>
          <div className="header-titles">
            <h2>{data.name}</h2>
            <h4>{data.titles[0]}</h4>
          </div>
        </div>

        <ul className="main-menu">
          <li className="active">
            <a href="#home" className="nav-anim">
              <span className="menu-icon lnr lnr-home"></span>
              <span className="link-text">Home</span>
            </a>
          </li>
          <li>
            <a href="#resume" className="nav-anim">
              <span className="menu-icon lnr lnr-graduation-hat"></span>
              <span className="link-text">Resume</span>
            </a>
          </li>
          <li>
            <a href="#contact" className="nav-anim">
              <span className="menu-icon lnr lnr-envelope"></span>
              <span className="link-text">Contact</span>
            </a>
          </li>
        </ul>

        <div className="social-links">
          <ul>
            {!!data.social.linkedin ? (
              <li><a href={data.social.linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
            ) : ''}
            {!!data.social.facebook ? (
              <li><a href={data.social.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
            ) : ''}
            {!!data.social.twitter ? (
              <li><a href={data.social.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
            ) : ''}
          </ul>
        </div>

        <div className="header-buttons" style={{display: 'none'}}>
          <a href="#" target="_blank" className="btn btn-primary">Download CV</a>
        </div>

        <div className="copyrights">© {(new Date()).getFullYear()} All rights reserved.</div>
      </header>
    </>
);
}
