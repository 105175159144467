import React from 'react';
import {Data} from "../../service/Data";

export default function Home(data: Data) {
  return (
    <>
      <section data-id="home" className="animated-section start-page">
        <div className="section-content vcentered">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="title-block">
                <h2>{data.name}</h2>
                <div className="owl-carousel text-rotation">
                  {data.titles.slice(0, 1).map((title: string, i) =>
                      <div key={i} className="item"><div className="sp-subtitle">{title}</div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
