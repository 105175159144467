import React from 'react';
import {ResumeSectionDataItem} from "../../service/Data";
import ResumeSectionItem from "./ResumeSectionItem";

export default function ResumeSection(name: string, dataItems: ResumeSectionDataItem[]) {
  return (
      <>
        <div className="block-title">
          <h3>{name}</h3>
        </div>
        <div className="timeline timeline-second-style clearfix">
          {dataItems.map((dataItem: ResumeSectionDataItem, i) =>
            ResumeSectionItem(dataItem)
          )}
        </div>
      </>
  );
}
